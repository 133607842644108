<template>
  <div class="min-h-dvh flex flex-col">
    <audoora-header class="relative z-30" />
    <main class="flex-grow">
      <storyblok-component v-if="story" :blok="story.content" />
    </main>
    <audoora-footer class="relative z-30" />
  </div>
</template>

<script setup lang="ts">
import { environmentKey, isEditorKey, storyblokEnvKey, storyKey } from "~/injection-symbols"

definePageMeta({
  pageTransition: {
    name: "page-reverse",
  },
})

const route = useRoute()
const environment = inject(environmentKey)

const storyblokEnv = environment === "development" || environment === "staging" ? "draft" : "published"

const story = await useSbStory("/index")
useTracking()

useHead({
  link: [
    {
      rel: "canonical",
      href: useSiteConfig().url,
    },
  ],
})

provide(storyKey, story.value)
provide(storyblokEnvKey, storyblokEnv)
provide(isEditorKey, !!route.query._storyblok)
</script>
